import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { Loading } from '@fleet/components';

import { Routes } from '../const';

export default function Index() {
  const router = useRouter();

  useEffect(() => {
    const navigateToSummary = setTimeout(() => router.replace(Routes.SUMMARY), 1000);

    return () => clearTimeout(navigateToSummary);
  }, [router]);

  return <Loading />;
}
